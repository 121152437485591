import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, ColorBatch, PriceLabel } from 'components';
import { DEFAULT_CURRENCY, TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_TYPE, TRANSACTION_SUB_KINDS } from 'enums';
import { ReactComponent as EmptyIcon } from 'assets/images/not-have.svg';
import { walletTransactionContainer } from './styles';

const WalletTransactionsCart = ({ transactions, navigateTo }) => {
  const history = useHistory();
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        fiatOnly={false}
      />
    );

  return (
    <div css={walletTransactionContainer}>
      <div className="titles-container">
        <h4 className="title-styles-container">{getText('latestTransactions')}</h4>
        <Button type="link" onClick={() => history.push(navigateTo)} className="action-button">
          {getText('viewAll')}
        </Button>
      </div>

      {transactions?.length ? (
        <div className="transactions-container">
          {transactions?.map((el, ind) => (
            <div key={ind} className="transactions-styles">
              <div className="date-kind">
                <h5 className="date">{moment(el.createdDate).format('DD MMM')}</h5>
                <p>{el.reason}</p>
              </div>
              <div className="status-price">
                <ColorBatch type={TRANSACTION_STATUS_COLOR[el?.status]?.color} className="color-batch">
                  {getText(TRANSACTION_STATUS_TYPE[el?.status])}
                </ColorBatch>
                <p>
                  {el.transactionSubKind === TRANSACTION_SUB_KINDS.OTHER ? (
                    el.amount
                  ) : el.transactionSubKind === TRANSACTION_SUB_KINDS.BOOKING_PAYMENT_WITH_CRYPTO ? (
                    <PriceLabel crypto={Number(el.amount) ?? 0} ignoreEmpty fiatOnly={false} />
                  ) : (
                    convertedToLocalCurrencyPrice(el.amount)
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="not-have-transactions">
          <EmptyIcon className="empty-icon" />
          <h4 className="title">{getText('thereAreNoTransactions')}</h4>
        </div>
      )}
    </div>
  );
};

export default WalletTransactionsCart;
