import { ROUTES } from 'enums';
import { BookingDetails, ProfileDetails, SuccessCancelBooking, TransactionDetails } from '../private';

export const PrivateRoutes = {
  transactions: {
    internal: [
      {
        url: ROUTES.TransactionsDetails,
        component: TransactionDetails,
      },
    ],
  },
  profiles: {
    internal: [
      {
        url: ROUTES.ProfileDetails,
        component: ProfileDetails,
      },
    ],
  },
  bookings: {
    internal: [
      {
        url: ROUTES.BookingDetails,
        component: BookingDetails,
      },
      {
        url: ROUTES.SuccessCancelBooking,
        component: SuccessCancelBooking,
      },
    ],
  },
};
