import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, ColorBatch, Image, PriceLabel } from 'components';
import { BOOKING_STATUS_COLOR, BOOKING_STATUSES_INVERSE, BOOKING_TYPES_COLOR, DEFAULT_CURRENCY } from 'enums';
import { ReactComponent as EmptyIcon } from 'assets/images/not-have.svg';
import { bookingsCartContainer } from './styles';

const BookingCart = ({ bookings, bookingType, navigateTo }) => {
  const history = useHistory();
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  return (
    <div css={bookingsCartContainer(BOOKING_TYPES_COLOR[bookingType]?.color)}>
      <div className="titles-container">
        <h5 className="title-styles-container">{getText('hotelBookings')}</h5>
        <Button type="link" onClick={() => history.push(navigateTo)} className="action-button">
          {getText('viewAll')}
        </Button>
      </div>

      {bookings?.length ? (
        <div className="bookings-container">
          <div className="header-color" />

          {bookings?.map((el, ind) => (
            <div key={ind} className="bookings">
              <div className="booking-info">
                <Image src={el.hotelImage} className="image" />
                <div className="hotel">
                  <h5 className="name">{el.hotelName}</h5>
                  <div className="location">
                    {el?.address?.address && <span>{`${el?.address?.address}`}</span>}
                    {el?.address?.cityName && <span>{`, ${el?.address?.cityName}`}</span>}
                    {el?.address?.stateName && <span>{`, ${el?.address?.stateName}`}</span>}
                    {el?.address?.countryName && <span>{`, ${el?.address?.countryName}`}</span>}
                  </div>
                </div>
              </div>
              <div className="status-price">
                <p className="price">
                  <PriceLabel
                    fiat={Number(el.fiatPrice)}
                    crypto={Number(el.cryptoPrice) ?? 0}
                    fiatOriginCurrency={DEFAULT_CURRENCY.code}
                    fiatDesiredCurrency={selectedCurrency?.code}
                    fiatOnly={false}
                  />
                </p>
                <ColorBatch type={BOOKING_STATUS_COLOR[el?.status]?.color}>
                  {getText(BOOKING_STATUSES_INVERSE[el?.status])}
                </ColorBatch>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="not-have-bookings">
          <EmptyIcon className="empty-icon" />
          <h4 className="title">{getText('thereAreNoBookings')}</h4>
        </div>
      )}
    </div>
  );
};

export default BookingCart;
