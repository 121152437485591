import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './Login';
import { ROUTES } from 'enums';
import { publicContentContainer } from './styles';

export const PublicRoutes = () => (
  <section css={publicContentContainer}>
    <Switch>
      <Route exact path={ROUTES.Login} component={Login} />
      <Redirect from={ROUTES.HomePage} to={ROUTES.Login} />
    </Switch>
  </section>
);
