import queryString from 'query-string';
import { isNil, pick } from 'lodash-es';

export const getAllUsersParams = (options = {}) => {
  const { userId, email, username } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(!isNil(userId) && { userId }),
      ...(!isNil(email) && { email }),
      ...(!isNil(username) && { username }),
    },
    { arrayFormat: 'index' },
  );
};
