import { theme } from 'styles';

export const modalStyles = {
  '.close-icon': {
    top: 32,
  },

  '.title': {
    marginBottom: 24,
    borderBottom: `1px solid ${theme.gray150}`,
    paddingBottom: 16,
    fontWeight: 500,
    fontSize: 22,
  },

  '.rows': {
    marginBottom: 24,

    '.input-container': {
      border: `1px solid ${theme.gray150}`,
      borderRadius: '0.8rem',
      minHeight: '4.8rem',
      padding: '0.7em 0.85em',
      width: '100%',
      marginBottom: 3,
      outline: 'none',
      paddingTop: 24,
      borderColor: theme.gray150,
      color: theme.gray200,
      backgroundColor: theme.whiteGray,
      cursor: 'not-allowed',
      position: 'relative',

      '.input-label': {
        fontSize: 13,
        fontWeight: 500,
        color: theme.gray200,
        margin: '0 0 0.3em 0',
        position: 'absolute',
        top: 6,
        left: 12,
        zIndex: 10,
        marginBottom: 4,
      },

      '.euro-symbol-styles': {
        top: '2.5rem',
      },

      '.one-symbol-styles': {
        top: '2.5rem',
        fontSize: 14,
      },

      '.value-one': {
        marginLeft: '35%',
        fontSize: 13,
      },

      '.value-euro': {
        marginLeft: '15%',
        fontSize: 13,
      },
    },

    '.add-icon': {
      margin: '20px',
    },

    '.euro-symbol-styles': {
      top: '2.5rem',
    },

    '.coin-input': {
      '.input': {
        paddingLeft: '4.2em',
      },

      '.one-symbol-styles': {
        top: '2.5rem',
        fontSize: 14,
      },
    },
  },

  '.column-border-bottom': {
    borderBottom: `1px solid ${theme.gray150}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '.check-container': {
    '.label': {
      color: theme.black,
      fontWeight: 500,
      marginBottom: 8,
    },

    '.description': {
      color: theme.gray300,
    },
  },

  '.actions': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > div': {
      fontWeight: 500,
      color: theme.black,
    },

    '.cancel-button': {
      marginRight: 16,
    },
  },
};
