import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Modal, PaymentMethodCart } from 'components';
import { ReactComponent as EmptyIcon } from 'assets/images/not-have.svg';
import { featureCartContainer, modalStyles } from './styles';

const FeatureCart = forwardRef(
  ({ feature, featureTitle, featurePaymentMethods, paymentMethodsToAdd, onUpdate }, ref) => {
    const { getText } = useTranslations();
    const modalRef = useRef();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);

    useImperativeHandle(ref, () => ({
      open: () => modalRef.current?.open(),
      close: () => modalRef.current?.close(),
      removeSelectedPaymentMethods: () => setSelectedPaymentMethod([]),
    }));

    const handleSelectPaymentMethod = (method) => {
      setSelectedPaymentMethod((prev) =>
        isEmpty(prev.find((el) => el.name === method.name))
          ? [...prev, method]
          : prev.filter((el) => el.name !== method.name),
      );
    };

    return (
      <>
        <div css={featureCartContainer}>
          <h4 className="feature-title">{featureTitle}</h4>
          <div className="scrollable-feature-container">
            {featurePaymentMethods?.length > 0 &&
              featurePaymentMethods?.map((el, ind) => (
                <div key={ind} className="card-container">
                  <PaymentMethodCart
                    item={el}
                    onRemove={() =>
                      onUpdate(
                        feature,
                        featurePaymentMethods?.map((element) => element.name)?.filter((elem) => el.name !== elem),
                      )
                    }
                    feature={feature}
                  />
                </div>
              ))}
          </div>

          <Button
            type="secondary"
            leftIcon={<Icon iconName="add" size={20} className="icon" />}
            className="add-button"
            onClick={() => modalRef.current.open()}>
            {getText('addPaymentMethod')}
          </Button>
        </div>

        <Modal ref={modalRef} css={modalStyles} closeIcon>
          <h4 className="modal-title">{getText('selectPaymentMethod')}</h4>

          <div className="scrollable-container">
            {paymentMethodsToAdd?.length > 0 ? (
              paymentMethodsToAdd?.map((el, ind) => (
                <div key={ind} className="payment-method-to-add-container">
                  <PaymentMethodCart
                    item={el}
                    onSelect={handleSelectPaymentMethod}
                    selectedPaymentMethod={selectedPaymentMethod}
                  />
                </div>
              ))
            ) : (
              <div className="not-have-bookings">
                <EmptyIcon className="empty-icon" />
                <h4 className="title">{getText('thereAreNoPaymentMethods')}</h4>
              </div>
            )}
          </div>

          <div className="actions">
            <Button type="secondary" onClick={() => modalRef.current?.close()} className="cancel-button">
              {getText('cancel')}
            </Button>
            <Button
              disabled={isEmpty(selectedPaymentMethod)}
              onClick={() =>
                onUpdate(
                  feature,
                  selectedPaymentMethod?.map((el) => el.name).concat(featurePaymentMethods?.map((el) => el.name)),
                )
              }>
              {getText('add')}
            </Button>
          </div>
        </Modal>
      </>
    );
  },
);

export default FeatureCart;
