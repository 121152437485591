import { useState, forwardRef, useImperativeHandle } from 'react';
import { isBoolean } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  Icon,
  Button,
  EditSubscriptionCatalogueCart,
  EditSubscriptionTypeCart,
  parseJson,
  PriceLabel,
} from 'components';
import { SUBSCRIPTION_TYPE, DEFAULT_CURRENCY } from 'enums';
import { subscriptionContainer, benefits } from './styles';

const SubscriptionCart = forwardRef((props, ref) => {
  const { subscription, type, onUpdateSubscription } = props;
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const [isEdit, setIsEdit] = useState(false);
  const includes = parseJson(subscription?.includes, []);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        fiatOnly={false}
      />
    );

  useImperativeHandle(ref, () => ({
    handleEdit: (newValue) => setIsEdit(newValue),
  }));

  return (
    <div css={subscriptionContainer(subscription?.isRecommended)}>
      {subscription?.isRecommended && (
        <div className="recommended-container">
          <Icon material iconName="auto_awesome" color="blueDarker" className="recommended-icon" />
          <p className="recommended-text">{getText('recommended')}</p>
        </div>
      )}
      {isEdit && type === SUBSCRIPTION_TYPE.CATALOGUE ? (
        <EditSubscriptionCatalogueCart
          subscription={subscription}
          handleEdit={(val) => setIsEdit(val)}
          onUpdateSubscription={onUpdateSubscription}
        />
      ) : isEdit && type === SUBSCRIPTION_TYPE.TYPE ? (
        <EditSubscriptionTypeCart
          subscription={subscription}
          handleEdit={(val) => setIsEdit(val)}
          onUpdateSubscription={onUpdateSubscription}
        />
      ) : (
        <>
          <p className="plan">{getText(subscription?.subscriptionTypeName ?? subscription?.name)}</p>
          {subscription?.price && <h1>{convertedToLocalCurrencyPrice(subscription?.price)}</h1>}
          {subscription?.duration && (
            <p className="per-text">{getText('forMonths', { count: subscription?.duration })}</p>
          )}
          {(isBoolean(subscription?.isVisible) || isBoolean(subscription?.isActive)) && (
            <div className="active-visible-container">
              <div className="row-info">
                <Icon
                  iconName={subscription?.isVisible ? 'done' : 'close'}
                  color={subscription?.isVisible ? 'success' : 'error'}
                  size={32}
                />
                <p>{getText('visible')} </p>
              </div>
              <div className="row-info">
                <Icon
                  iconName={subscription?.isActive ? 'done' : 'close'}
                  color={subscription?.isActive ? 'success' : 'error'}
                  size={32}
                />
                <p>{getText('active')}</p>
              </div>
            </div>
          )}
          {!!includes?.length && <h5 className="includes">{getText('includes')}</h5>}
          {includes?.length ? (
            includes?.map((el, ind) => (
              <div key={ind} css={benefits(el?.strike, el?.hasBvPoints)}>
                <Icon
                  material
                  iconName={el.strike ? 'check_small' : 'close'}
                  color={el.strike ? 'success' : 'secondaryLightDark'}
                  size={el.strike ? 28 : 16}
                  className="icon"
                />
                <p>{getText(el.label)}</p>
              </div>
            ))
          ) : (
            <div css={benefits(true, false)}>
              {includes?.title && <Icon material iconName="check_small" color="success" size={28} className="icon" />}
              {includes?.title && <p className="bolded">{includes?.title}</p>}
            </div>
          )}
          <Button
            small
            type={subscription?.isRecommended ? 'primary' : 'secondary'}
            className="button"
            onClick={() => setIsEdit(true)}>
            {getText('edit')}
          </Button>
        </>
      )}
    </div>
  );
});

export default SubscriptionCart;
