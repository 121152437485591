import { invert } from 'lodash-es';

export const BOOKING_TYPES = {
  HOTEL: 'Hotel',
  FLIGHT: 'Flight',
  CAR: 'Car',
  CRUISE: 'Cruise',
  ACTIVITY: 'Activity',
};

export const BOOKING_TYPES_COLOR = {
  [BOOKING_TYPES.HOTEL]: {
    color: 'yellowDarker',
  },
  [BOOKING_TYPES.FLIGHT]: {
    color: 'pinkLight',
  },
  [BOOKING_TYPES.CAR]: {
    color: 'success',
  },
  [BOOKING_TYPES.CRUISE]: {
    color: 'error',
  },
  [BOOKING_TYPES.ACTIVITY]: {
    color: 'red',
  },
};

export const BOOKING_STATUSES = {
  COMPLETED: 'Completed',
  REFUNDED: 'Refunded',
  PROCESSING_PAYMENT: 'ProcessingPayment',
  PAYMENT_FAILED: 'PaymentFailed',
  PROCESSING_REFUND: 'ProcessingRefund',
  PAYMENT_COMPLETED: 'PaymentCompleted',
  REJECTED_BY_PROVIDER: 'RejectedByProvider',
};

export const BOOKING_STATUSES_INVERSE = invert(BOOKING_STATUSES);

export const BOOKING_STATUS_COLOR = {
  [BOOKING_STATUSES.COMPLETED]: {
    color: 'success',
  },
  [BOOKING_STATUSES.REFUNDED]: {
    color: 'warning',
  },
  [BOOKING_STATUSES.PROCESSING_PAYMENT]: {
    color: 'info',
  },
  [BOOKING_STATUSES.PAYMENT_COMPLETED]: {
    color: 'pink',
  },
  [BOOKING_STATUSES.PAYMENT_FAILED]: {
    color: 'error',
  },
  [BOOKING_STATUSES.PROCESSING_REFUND]: {
    color: 'blue',
  },
  [BOOKING_STATUSES.REJECTED_BY_PROVIDER]: {
    color: 'red',
  },
};

export const BOOKINGS_SORTING_OPTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};
