import { forwardRef, useImperativeHandle, useRef } from 'react';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, CheckButton, Col, Input, Form, Modal, Row, Icon } from 'components';
import { modalStyles } from './styles';

const positiveIntPattern = (val) => /^[0-9]$/.test(val);

const AddEditPaymentMethodModal = forwardRef((props, ref) => {
  const { paymentMethod, title, isEditMode = false, handleSubmit } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  return (
    <Modal ref={modalRef} css={modalStyles} closeIcon>
      <h4 className="title">{title}</h4>

      <Form
        values={!isNil(paymentMethod) ? paymentMethod : { isVisible: true, isActive: true }}
        onSubmit={(val) => handleSubmit(val)}>
        <Row gap={8} className="rows">
          <Col sm={12} md={12}>
            <Input required disabled={isEditMode} formId="name" label={getText('paymentMethodName')} />
          </Col>
          <Col sm={12} md={5}>
            {isEditMode ? (
              <div className="input-container">
                <label className="input-label">{`${getText('cryptoPercent')} *`}</label>
                <div>
                  <Icon
                    position="absolute"
                    top="2.25rem"
                    left="1.2rem"
                    size={18}
                    iconName="ONE"
                    className="one-symbol-styles"
                    color="gray400"
                  />
                  <p className="value-one">{paymentMethod.coinPercent}</p>
                </div>
              </div>
            ) : (
              <Input
                required
                disabled={isEditMode}
                type="number"
                placeholder="0"
                formId="coinPercent"
                className="coin-input"
                label={getText('cryptoPercent')}
                leftIcon={{ iconName: 'ONE', className: 'one-symbol-styles', color: 'gray400' }}
                pattern={positiveIntPattern}
              />
            )}
          </Col>
          <Col sm={12} md={2}>
            <Icon iconName="add" color="gray400" className="add-icon" />
          </Col>
          <Col sm={12} md={5}>
            {isEditMode ? (
              <div className="input-container">
                <label className="input-label">{`${getText('fiatPercent')} *`}</label>
                <div>
                  <Icon
                    position="absolute"
                    top="2.25rem"
                    left="1.2rem"
                    size={18}
                    iconName="euro_symbol"
                    className="euro-symbol-styles"
                    color="gray400"
                  />
                  <p className="value-euro">{paymentMethod.cashPercent}</p>
                </div>
              </div>
            ) : (
              <Input
                required
                disabled={isEditMode}
                type="number"
                placeholder="0"
                formId="cashPercent"
                label={getText('fiatPercent')}
                leftIcon={{ iconName: 'euro_symbol', className: 'euro-symbol-styles', color: 'gray400' }}
                pattern={positiveIntPattern}
              />
            )}
          </Col>
          <Col sm={12} md={12} className="column-border-bottom">
            <div className="check-container">
              <p className="label">{getText('visible')}</p>
              <p className="description">{getText('paymentMethodVisible')}</p>
            </div>
            <CheckButton formId="isVisible" />
          </Col>
          <Col sm={12} md={12} className="column-border-bottom">
            <div className="check-container">
              <p className="label">{getText('active')}</p>
              <p className="description">{getText('paymentMethodActive')}</p>
            </div>
            <CheckButton formId="isActive" />
          </Col>
        </Row>
        {({ onSubmit }) => (
          <div className="actions">
            <Button type="secondary" onClick={() => modalRef.current?.close()} className="cancel-button">
              {getText('cancel')}
            </Button>
            <Button onClick={onSubmit}>{isEditMode ? getText('edit') : getText('create')}</Button>
          </div>
        )}
      </Form>
    </Modal>
  );
});

export default AddEditPaymentMethodModal;
